import _axios from "axios";
import { gitInfo } from "./version";
import endpoint from "./apiEndpoint";
import { restDevApiUrl, restRelApiUrl } from "./config";
import jsons, { headers } from "./jsons";

//---------------------------------------------------------------------------
// console.log('gitInfo.branch', gitInfo.branch);
let restApiUrl = "";

restApiUrl =
    gitInfo.branch === "master" || gitInfo.branch === "hotfix"
      ? restRelApiUrl
      : restDevApiUrl;

// const restTimeout = 5000
const restTimeout = 0; //no limit

const axios = (config) => {
  // console.log('restApiUrl', restApiUrl);
  const instance = _axios.create({
    baseURL: config || restApiUrl,
    timeout: restTimeout,
    // CORS BE suggestion
    /* crossOrigin: true,
    cors: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers": "Content-Type",
      "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE",
      "Access-Control-Allow-Headers": "Content-Type, Authorization",
      "Access-Control-Allow-Credentials": "false",
      "Access-Control-Expose-Headers": "X-My-Custom-Header",
    }, */
  });
  // console.log(instance)
  return instance;
};

export class axiosState {
  static set(isLoading, isTimeout, isResultCode200, numResultError) {
    return { isLoading, isTimeout, isResultCode200, numResultError };
  }
  static init() {
    return {
      isLoading: false,
      isTimeout: false,
      isResultCode200: false,
      numResultError: 0,
    };
  }
  static error(isTimeout, numResultError) {
    return {
      isLoading: false,
      isTimeout: isTimeout,
      isResultCode200: false,
      numResultError: numResultError,
    };
  }
  static resultCode200() {
    return {
      isLoading: false,
      isTimeout: false,
      isResultCode200: true,
      numResultError: 0,
    };
  }
  static keepRest(state) {
    if (
      state.isTimeout === false &&
      state.isResultCode200 === false &&
      state.numResultError < 5
    )
      return true;
    else return false;
  }
  static loadingState(state) {
    return {
      isLoading: true,
      isTimeout: false,
      numResultError: 0,
    };
  }
}

export class axiosCatch {
  static getMsg(err) {
    let msg = err.message;
    if (err.response !== undefined) {
      // console.log(err.response.status);
      // console.log(err.status);
      switch (err.response.status) {
        case 400:
        case 401:
        case 500:
          msg = err.response.data ? err.response.data.message : err.statusText;
          break;
        default:
          msg = err.message;
      }
    }
    return msg;
  }
  static getResultCode(err) {
    let code = 0;
    if (err.response !== undefined && err.response.data !== undefined) {
      code = err.response.data[jsons.common.response.code];
      // console.log(err.response);
      // console.log(code);
    }
    return code;
  }
  static isTimeout(err) {
    if (err.code === "ECONNABORTED")
      //msg === 'timeout of 5000ms exceeded'
      return true;
    return false;
  }
  static needLogin(err) {
    let code = 0;
    if (err.response !== undefined && err.response.data !== undefined) {
      code = err.response.data[jsons.common.response.code];
      if (code === 910 || code === 911 || code === 913) return true;
    }
    return false;
  }
}

class axiosConfig {
  static post = (input, url, timeout = restTimeout) => ({
    method: "post",
    url: url,
    headers: headers.json(),
    data: JSON.stringify(input),
    timeout: timeout,
  });
  static postDefault = (input, url) => ({
    method: "post",
    url: url,
    headers: headers.jsonDefault,
    data: JSON.stringify(input),
  });
  static postFile = (data, url) => ({
    method: "post",
    url: url,
    headers: {
      ...headers.json(),
      "Content-Type": "multipart/form-data",
    },
    data: data,
    maxBodyLength: Infinity,
  })
  //---------------------------------------------------------------------------
  static get = (url, timeout) => ({
    method: "get",
    url: url,
    headers: headers.json(),
    timeout: timeout,
  });
  static getDefault = (url) => ({
    method: "get",
    url: url,
    headers: headers.jsonDefault,
  });
}

//---------------------------------------------------------------------------
// site config
class ig {
  static c27 = (input) =>
    axiosConfig.post(input, endpoint.ig.c27);
}

//---------------------------------------------------------------------------
export const apiConfig = {
  ig,
};

export { axios };
export default axios();
