/* 
https://codesandbox.io/p/sandbox/react-video-upload-8tqik
Take a screenshot of an HTML 5 video (forked) - StackBlitz
https://stackblitz.com/edit/react-391emk
 */
import { Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
/* ES6 */
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

import { s3, s3params } from "../views/UploadVideo";

//------------------------------------------------------------------------------
export default function PreviewMedia(props) {
  const { width, height, file } = props;
  
  const [fileName, setFileName] = useState('')
  const [source, setSource] = React.useState();
  const [isImage, setImage] = useState(true)
  const [isVideo, setVideo] = useState(false)
  
  function setUrl(file) {
    setFileName(file.name)
    const url = URL.createObjectURL(file)
    setSource(url);
    
    // console.log('file', file);
    const isImageFile = () => file.type.startsWith('image')
    const isVideoFile = () => file.type.startsWith('video')
    setImage(isImageFile)
    setVideo(isVideoFile)
  }

  useEffect(() => {
    setUrl(file);
  }, [file])
  
  //------------------------------------------------------------------------------
  const videoRef = useRef();
  const canvasRef = useRef();
  const [dimensions, setDimensions] = useState({});

  let context;
  if (canvasRef.current) {
    context = canvasRef.current.getContext('2d');
  }

  function getVideoSizeData(videoRef) {
    const ratio = videoRef.current.videoWidth / videoRef.current.videoHeight;
    const w = videoRef.current.videoWidth - 100;
    const h = parseInt(w / ratio, 10);
    return {
      ratio,
      w,
      h
    };
  }
  
  function snap() {
    // console.log('context', context);
    if (context && videoRef.current) {
      context.fillRect(0, 0, dimensions.w, dimensions.h);
      context.drawImage(videoRef.current, 0, 0, dimensions.w, dimensions.h);
      
      var node = document.getElementById('video-shot');
      htmlToImage.toBlob(node)
      .then(function (blob) {
        const filename = fileName+'.jpg'
        var fileShot = new File([blob], filename, { type: 'image/jpg' })
        setFileShot(fileShot)
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
      setDisabledUpload(false)
    } else {
      setFileShot(null)
      setDisabledUpload(true)
    }
  }

  useEffect(() => {
    // Add listener when the video is actually available for
    // the browser to be able to check the dimensions of the video.
    if (videoRef.current) {
      videoRef.current.addEventListener('loadedmetadata', function() {
        const { w, h } = getVideoSizeData(videoRef);

        canvasRef.current.width = w;
        canvasRef.current.height = h;
        setDimensions({
          w: w,
          h: h
        });
      });
    }
  }, [isVideo]);
  //------------------------------------------------------------------------------
  const [isDisabledShot, setDisabledShot] = useState(false)
  const [isDisabledUpload, setDisabledUpload] = useState(true)
  const [isLoading, setLoading] = React.useState(false);
  const [fileShot, setFileShot] = useState(null);
  const [s3Url, setS3Url] = useState('')
  
  const uploadFile = async (file) => {
    setDisabledShot(true)
    setDisabledUpload(true)
    setLoading(true)
    
    // Files Parameters
    const params = s3params(file)

    // Uploading file to s3
    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // File uploading progress
        // console.log("Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%");
      })
      .promise();

    await upload.then((res, err) => {
      if (err) {
        console.log('Error', err);
      } else {
        // Fille successfully uploaded
        // alert("File uploaded successfully");
        setS3Url(`s3://${params.Bucket}/${params.Key}`)
        setLoading(false)
        
        setDisabledShot(false)
        setDisabledUpload(false)
      }
    });
  };
  
  //------------------------------------------------------------------------------
  return (
    <div>
      {isImage && (
        <img
          className="VideoInput_image"
          height={height}
          src={source}
        />
      )}
      {isVideo && (
        <div>
          <Button variant="contained" onClick={snap}
            disabled={isDisabledShot}>
            Screenshot</Button>
          <span> </span>
          <LoadingButton variant="contained" onClick={() => uploadFile(fileShot)}
            loading={isLoading}
            disabled={isDisabledUpload}
          >
            Upload shot</LoadingButton>
          <br />
          {s3Url}
          <p />
          
          <video
            className="VideoInput_video"
            width="100%"
            height={height}
            controls
            src={source}
            ref={videoRef}
          />
          <br />
          <canvas crossOrigin="anonymous"
            ref={canvasRef}
            style={{maxHeight: height}}
            id='video-shot'
            />
          <br />
        </div>
      )}
      {!isImage && !isVideo ? "Unsupported Format" : null}
    </div>
  );
}
