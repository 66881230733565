// define BE endpoint url
//---------------------------------------------------------------------------
// Web site
const ig = {
  c27: "/v1/client/post/publish", //c27
};
//---------------------------------------------------------------------------
const endpoint = {
  ig,
};
export default endpoint;
