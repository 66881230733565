/*
How to upload files on an s3 bucket in React js | by Manish Mandal | How To React | Medium
https://medium.com/how-to-react/how-to-upload-files-on-an-s3-bucket-in-react-js-97a3ccd519d1
*/
import { LoadingButton } from '@mui/lab';
import { Button, FilledInput, TextField } from '@mui/material';
import Input from '@mui/material/Input';
import AWS from "aws-sdk";
import React, { useState } from 'react';

import PreviewMedia from '../components/PreviewMedia';
import axios, { apiConfig, axiosCatch, axiosState } from '../utils/axios';
import jsons from '../utils/jsons';


//------------------------------------------------------------------------------
// S3 Bucket Name
const S3_BUCKET = "ig-dev-bucket";
// S3 Region
const REGION = "ap-southeast-1";

// S3 Credentials
AWS.config.update({
  accessKeyId: "AKIATXFTMTQB7VLF7JAC",
  secretAccessKey: "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",
});

export const s3 = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export const s3params = (file) => ({
    Bucket: S3_BUCKET,
    Key: `upload/${file.name}`,
    Body: file,
})

//------------------------------------------------------------------------------
const UploadVideo = () => {
  const [isDisabledInput, setDisabledInput] = useState(false)
  const [isDisabledUpload, setDisabledUpload] = useState(true)
  const [isLoading, setLoading] = React.useState(false);
  
  // Create state to store file
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('')
  const [s3Url, setS3Url] = useState('')

  // Function to upload file to s3
  const uploadFile = async (file) => {
    setDisabledInput(true)
    setDisabledUpload(true)
    setDisabledApi(true)
    setLoading(true)

    // Files Parameters
    const params = s3params(file)

    // Uploading file to s3
    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // File uploading progress
        // console.log("Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%");
      })
      .promise();

    await upload.then((res, err) => {
      if (err) {
        console.log('Error', err);
      } else {
        // Fille successfully uploaded
        console.log("Uploaded successfully " + file.name);
        setS3Url(`s3://${params.Bucket}/${params.Key}`)
        
        const isVideoFile = file.type.startsWith('video')
        if(isVideoFile)
          setDisabledApi(false)
      }
      setLoading(false)
      
      setDisabledInput(false)
      setDisabledUpload(false)
    });
  };
  // Function to handle file and store it to file state
  const handleFileChange = (e) => {
    // Uploaded file
    const file = e.target.files[0];
    // console.log('file', file);
    
    // Changing file state
    setFile(file);
    setFileName(file.name)
    setS3Url('')
    setDisabledUpload(false)
    setApiCode('')
    setApiMsg('')
    setDisabledApi(true)
  };
  //------------------------------------------------------------------------------
  const [isDisabledApi, setDisabledApi] = useState(true)
  
  const [apiCode, setApiCode] = useState('')
  const [apiMsg, setApiMsg] = useState('')
  const [state, setState] = useState(axiosState.init())
  const callApi = async () => {
    setState(axiosState.loadingState())
    setApiMsg('')
    
    const input = jsons.ig.c27.input(s3Url, `${s3Url}.jpg`)
    const config = apiConfig.ig.c27(input)
    // console.log(input);
    // console.log(config);
    axios(config).then(result => {
      const data = result.data;
      // console.log('data', data);
      if (result.data.result_code !== 200) {
        setState(axiosState.error(false, state.numResultError + 1))
        setApiMsg(data[jsons.common.response.reason])
        setApiCode(data.result_code)
        console.log('data', data);
      } else {
        setState(axiosState.resultCode200())
        setApiMsg(data[jsons.common.response.msg])
        setApiCode(data.result_code)
      }
    }).catch(err => {
      setState(axiosState.error(axiosCatch.isTimeout(err), state.numResultError + 1))
      // setInfo(snackInfo.open(snackType.error, axiosCatch.getMsg(err)))
      console.log('err', err);
    })
  }
  
  //------------------------------------------------------------------------------
    return (
      <div>
        Upload media to IG
        <p />
        
        <div>
          <label htmlFor="upload-photo">
            <input
              type="file"
              accept="image/*,video/*"
              style={{ display: 'none' }}
              id="upload-photo"
              onChange={handleFileChange}
            />
            <Button variant="contained" component="span"
              disabled={isDisabledInput}>
              {fileName || 'Browse'}
            </Button>
          </label>
          <span> </span>
          
          <LoadingButton variant="contained" onClick={() => uploadFile(file)}
            loading={isLoading}
            disabled={isDisabledUpload}
          >
            Upload to S3</LoadingButton>
          <span> </span>
          
          <LoadingButton variant="contained" onClick={callApi}
            loading={state.isLoading}
            disabled={isDisabledApi}
          >
            {apiCode || 'Call api C27'}</LoadingButton>
          <br />
          {s3Url}
          <br />
          {apiMsg}
        </div>
        <p />
        
        {file && (
          <PreviewMedia width={400} height={300}
            file={file} />
        )}
      </div>
    )
}
export default UploadVideo