import Cookies from 'universal-cookie'

//---------------------------------------------------------------------------
const keys = {
  auth: { //user
    token: "user_token",
  },
};

//---------------------------------------------------------------------------
const cookies = new Cookies();

/**
 * set cookie's key and value
 * @param {string} key
 * @param {*} value
 */
const setCookie = (key, value) => {
  cookies.set(key, value, {
    path: "/",
    secure: true,
    sameSite: true,
    // sameSite: "strict",
    expires: 0,
  })
  // console.log(cookies.get(key));
};

/**
 * get cookie's value
 * @param {string} key
 */
const getCookie = (key) => {
  if (cookies.get(key) === undefined) {
    return "";
  }
  return cookies.get(key);
};

/**
 * remove cookie
 * @param {string} key
 */
const removeCookie = (key) => {
  cookies.remove(key);
};

//---------------------------------------------------------------------------
const cookie = {
  setCookie,
  getCookie,
  removeCookie,
  
  keys,
};
export default cookie;
