import cookie from "./cookie";
import { tokenDefault } from "./config";

// property for Backend API & DB
function json() {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
    // "Access-Control-Allow-Methods": "GET, POST"
    "X-Auth-Token": cookie.getCookie(cookie.keys.auth.token),
  };
}

function token(token) {
  let tokenUser =
    token !== undefined ? token : cookie.getCookie(cookie.keys.auth.token);
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Auth-Token": tokenUser,
  };
}

const headers = {
  jsonDefault: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Auth-Token": tokenDefault,
  },

  json,
  token,
};

//---------------------------------------------------------------------------
// site
const ig = {
  c27: class {
    static input(fullpath, thn_path, title = 'title', content = 'content') {
      return {
        video: {
          fullpath,
          thn_path,
        },
        title,
        content,
      };
    }
    static output = [
      "content",
      "created",
      "image_url",
      "post_id",
      "title",
      "video",
    ];
  },
};

//---------------------------------------------------------------------------
const common = {
  response: {
    msg: "message",
    code: "result_code",
    reason: "reason",
  },
};

//---------------------------------------------------------------------------
const jsons = {
  headers,
  /* body */
  ig,

  common,
};
export { headers };
export default jsons;
